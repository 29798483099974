<template>
    <div>
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div v-if="event" class="context">
                <h2 class="date-title">{{ localize(event.attributes.title) }}</h2>
                <h3>{{ dateFormat(event.attributes.date) }}</h3>
            </div>
        </div>

        <div class="component-container">
            <section class="event" v-if="event">
                <div class="event-card">
                    <div class="inner-wrap">
                        <div class="image">
                            <picture>
                                <template v-for="[format, set] of Object.entries(srcsets)">
                                    <source v-if="format !== originalFormat" :srcset="set"
                                        sizes="(max-width: 1100px) 80vw,15vw" :type="'image/' + format.slice(1)">
                                </template>
                                <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                                    :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 80vw,5vw"
                                    :alt="altText" width="100%">
                            </picture>
                        </div>

                        <div class="content">
                            <div class="detail-column">
                                <div class="detail">
                                    <img src="@/assets/icons/calendar.svg" class="icon" />
                                    {{ dateFormatLong(this.event.attributes.date) }}
                                </div>
                                <div class="detail">
                                    <img src="@/assets/icons/time.svg" class="icon" />
                                    {{ timeFormat(this.event.attributes.startTime) + '–' +
                                        timeFormat(this.event.attributes.endTime) + ' ' + this.$t('oclock') }}
                                </div>
                                <div class="detail">
                                    <img src="@/assets/icons/location.svg" class="icon" />
                                    {{ this.localize(this.event.attributes.place) }}
                                </div>
                            </div>
                            <div class="detail-column">
                                <div class="detail">
                                    <img src="@/assets/icons/information-circle.svg" class="icon" />
                                    {{ this.localize(this.event.attributes.eventFormat.data.attributes.translatedName) }}
                                </div>
                                <div class="detail">
                                    <img src="@/assets/icons/chatbubble-ellipses.svg" class="icon" />
                                    {{ this.localize(this.event.attributes.content) }}
                                </div>
                                <div class="detail">
                                    <img src="@/assets/icons/person.svg" class="icon" />
                                    {{ this.localize(this.event.attributes.eventTargetGroup.data.attributes.translatedName)
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SpacerBlock :cmsBlock="{ spacerBackgroundStyle: 'white', spacing: 'small' }" />
                <div class="event-description" v-html="md(localize(event.attributes.description))"></div>
                <div class="event-buttons">
                    <a v-if="event.attributes.registerUrl" :href="event.attributes.registerUrl" target="_blank">
                        <button class="button-dark sign-up">
                            <span v-if="event.attributes.registerUrlText">
                                {{ localize(event.attributes.registerUrlText) }}
                            </span>
                            <span v-else>
                                {{ $t('signUpForFree') }}
                            </span>
                        </button>
                    </a>
                </div>
                <SpacerBlock :cmsBlock="{ spacerBackgroundStyle: 'white', spacing: 'small' }" />
                <h3 v-if="event.attributes.teamMember.data || event.attributes.ExternalTeamMember" class="event-contact">{{ $t('eventContact') }}
                </h3>
                <SpacerBlock :cmsBlock="{ spacerBackgroundStyle: 'white', spacing: 'small' }" />
                <div v-if="event.attributes.teamMember.data || event.attributes.ExternalTeamMember" class="event-person">
                    <img loading="lazy" decoding="async" :srcset="teamMemberSrcsets[teamMemberOriginalFormat]"
                        :type="'image/' + teamMemberOriginalFormat.slice(1)" sizes="(max-width: 1100px) 0vw,10vw"
                        alt="Blurred Background Image" width="100%"
                        class="background">
                    <div class="person-wrapper">
                        <div class="person">
                            <TeamMember v-if="event.attributes.teamMember.data" class="dark" :teamMember="event.attributes.teamMember" />
                            <TeamMember v-if="event.attributes.ExternalTeamMember && event.attributes.ExternalTeamMember.name !== null" class="dark" :teamMember="externalTeamMember" />
                        </div>
                    </div>
                </div>
                <SpacerBlock v-if="event && event.attributes.organizer && event.attributes.organizerLogo.data" :cmsBlock="{ spacerBackgroundStyle: 'white', spacing: 'small' }" />
                <div v-if="event && event.attributes.organizer && event.attributes.organizerLogo.data" class="event-organizer grey">
                    <h4>{{ $t('organizer') }}</h4>
                    <img class="organizer-logo" :src="getImageUrl(event.attributes.organizerLogo.data.attributes.url)"/>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { eventQuery, globalOptionQuery } from '@/queries'
import { config } from '@/config'
import { dateFormatLong, dateFormat, timeFormat } from '@/helper'
import ImageBlock from '@/components/PageBuilder/ImageBlock'
import Separator from '@/components/PageBuilder/Separator'
import TeamMember from '@/components/PageBuilder/Elements/TeamMember'
import SpacerBlock from '@/components/PageBuilder/SpacerBlock'
import router from '../router'

export default {
    name: 'Event',

    components: {
        ImageBlock,
        Separator,
        TeamMember,
        SpacerBlock
    },

    data() {
        return {
            event: null,
            route: this.$route
        }
    },

    apollo: {
        event: {
            query: eventQuery,
            variables() {
                return {
                    title: this.$route.params.title
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => {
                if (!data || !data.events || !data.events.data || data.events.data.length === 0) {
                    // replace with 404 component
                    router.push({ name: '404' })
                    return;
                }
                return data.events.data[0]
            }
        },

        defaultCmsPageHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'default-cms-page-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }
    },

    metaInfo() {
        var meta = []

        if (this.metaTitle) {
            meta.push({
                vmid: 'og:title',
                name: 'og:title',
                content: this.metaTitle
            })
        }
        if (this.metaDescription) {
            meta.push({
                vmid: 'description',
                name: 'description',
                content: this.metaDescription
            })
            meta.push({
                vmid: 'og:description',
                name: 'og:description',
                content: this.metaDescription
            })
        }
        if (this.metaImage) {
            meta.push({
                vmid: 'og:image',
                name: 'og:image',
                content: this.metaImage
            })
        }

        return {
            title: this.metaTitle,
            meta: meta
        }
    },

    computed: {
        metaTitle() {
            if (this.event) {
                var title = this.event.attributes.metaTitle ? this.localize(this.event.attributes.metaTitle) : this.localize(this.event.attributes.title)
                return config.globalPageTitle + ' - ' + title
            } else {
                return null
            }
        },

        metaDescription() {
            if (this.event) {
                return this.event.attributes.metaDescription ? this.localize(this.event.attributes.metaDescription) : this.localize(this.event.attributes.subtitle)
            } else {
                return null
            }
        },

        metaImage() {
            if (this.event && this.localize(this.event.attributes.metaImage) && this.localize(this.event.attributes.metaImage).data) {
                return this.getImageUrl(this.localize(this.event.attributes.metaImage).data.attributes.url)
            } else {
                return null
            }
        },

        cmsPageHeaderImage() {
            return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
        },

        altText() {
            if (this.localize(this.event.attributes.image) && this.localize(this.event.attributes.image).data){
                return this.localize(this.event.attributes.image).data.attributes.alternativeText
            }
            return "Event Title Image"
        },

        srcsets() {
            if (this.localize(this.event.attributes.image) && this.localize(this.event.attributes.image).data) { 
                return this.getImageSrcsets(this.localize(this.event.attributes.image).data.attributes)
            }
            return this.getPlaceholderSrcsets()
        },

        hasWebp() {
            return this.srcsets['.webp'] ? true : false
        },

        teamMemberSrcsets() {
            if (this.event.attributes.teamMember.data) {
                return this.getImageSrcsets(this.event.attributes.teamMember.data.attributes.image.data.attributes)
            } else if (this.event.attributes.ExternalTeamMember) {
                return this.getImageSrcsets(this.event.attributes.ExternalTeamMember.image.data.attributes)
            }
        },

        teamMemberHasWeb() {
            if (this.teamMemberSrcsets){
                return this.teamMemberSrcsets['.webp'] ? true : false
            }
            return undefined
        },

        originalFormat() {
            let originalFormat = "";
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== '.webp') {
                    originalFormat = format
                }
            }

            return originalFormat
        },

        teamMemberOriginalFormat() {
            if (!this.teamMemberSrcsets) {
                return undefined
            }

            let originalFormat = "";
            for (const [format, _] of Object.entries(this.teamMemberSrcsets)) {
                if (format !== '.webp') {
                    originalFormat = format
                }
            }

            return originalFormat
        },

        sharingUrl() {
            return this.getCurrentUrl(this.$route)
        },

        externalTeamMember() {
            if (this.event.attributes.ExternalTeamMember) {
                var teamMember = {
                    data: {
                        attributes: {
                            name: this.event.attributes.ExternalTeamMember.name,
                            image: this.event.attributes.ExternalTeamMember.image,
                            copyright: this.event.attributes.ExternalTeamMember.copyright,
                            bio: this.event.attributes.ExternalTeamMember.description
                        }
                    }
                    
                }
                return teamMember
            }
        }
    },

    methods: {
        dateFormat: dateFormat,
        dateFormatLong: dateFormatLong,
        timeFormat: timeFormat,
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .event {
        max-width: $container-width;
        margin: 0 auto;
        padding: getSpacing('padding-default-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: getSpacing('padding-default-vertical', 'tablet') getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: getSpacing('padding-default-vertical', 'mobile') getSpacing('padding-default-horizontal', 'mobile');
        }

        .event-card {
            background: $lightGrey;
            width: 100%;

            .inner-wrap {
                display: flex;
                flex-direction: row;
                min-height: 175px;

                @include breakpoint('mobile') {
                    flex-direction: column;
                }

                .image {
                    flex: 0 0 auto;
                    width: 312px;
                    min-height: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    @include breakpoint('mobile') {
                        width: 100%;
                    }
                }

                .content {
                    flex: 1 1 auto;
                    margin: 1rem 2rem;
                    display: flex;
                    flex-direction: row;
                    gap: 24px;

                    @include breakpoint('mobile') {
                        margin: 0.5rem 1rem;
                        flex-direction: column;
                        gap: unset;
                    }

                    .detail-column {
                        width: 100%;

                        .detail {
                            padding: 10px 0;
                            font-size: getFontSize('eventPageCardContent', 'desktop');
                            font-family: getFontFamily('eventPageCardContent');

                            @include breakpoint('tablet') {
                                padding: 7px 0;
                                font-size: getFontSize('eventPageCardContent', 'tablet');
                            }

                            @include breakpoint('mobile') {
                                padding: 3px 0;
                                font-size: getFontSize('eventPageCardContent', 'mobile');
                            }

                            .icon {
                                padding-right: 5px;
                                display: inline-block;
                                vertical-align: middle;
                                height: getFontSize('eventPageCardContent', 'desktop');
                            }
                        }
                    }
                }
            }
        }

        .event-description {

            ::v-deep p {
                font-family: getFontFamily('mediumText');
                font-size: getFontSize('mediumText', 'desktop');

                @include breakpoint('tablet') {
                    font-size: getFontSize('mediumText', 'tablet');
                }

                @include breakpoint('mobile') {
                    font-size: getFontSize('mediumText', 'mobile');
                    text-align: left;
                }
            }
        }

        .event-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: row;

            @include breakpoint('mobile') {
                flex-direction: column;
            }

            .sign-up {
                background: $timberGreen;
                color: white;
            }
        }

        .event-share {
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .share-link-items {
                margin-top: 10px;

                a {
                    margin: 10px;
                }
            }
        }

        .event-contact {
            width: 100%;
            text-align: center;
        }

        .event-person {
            position: relative;

            .background {
                position: absoulute;
                top: 0;
                left: 0;
                width: 100%;
                height: 640px;
                object-fit: cover;
                object-position: center;
            }

            .person-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);

                .person {

                    width: 50%;
                    margin: 0 auto;

                    @include breakpoint('mobile') {
                        width: unset;
                    }
                }
            }
        }

        .event-organizer {
            width: 100%;
            text-align: center;
            .organizer-logo {
                max-width: 200px;
            }
        }

    }
}



.entry-short {
    h2 {
        padding: 0 30px;
    }

    .date-title {
        font-family: getFontFamily('pageTitle');
    }
}
</style>