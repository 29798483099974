<template>
    <div class="spacer" :class="[cmsBlock.spacerBackgroundStyle, cmsBlock.spacing]" />
</template>

<script>
export default {
    name: 'SpacerBlock',

    props: [
        'cmsBlock'
    ],

    components: {
        
    },

    computed: {
        
    }
}
</script>

<style lang="scss" scoped>
.spacer {

    &.default {
        height: getSpacing('default-vertical', 'desktop');

        @include breakpoint('tablet') {
            height: getSpacing('default-vertical', 'tablet');
        }

        @include breakpoint('mobile') {
            height: getSpacing('default-vertical', 'mobile');
        }
    }

    &.small {
        height: getSpacing('small-vertical', 'desktop');

        @include breakpoint('tablet') {
            height: getSpacing('small-vertical', 'tablet');
        }

        @include breakpoint('mobile') {
            height: getSpacing('small-vertical', 'mobile');
        }
    }

    &.large {
        height: getSpacing('large-vertical', 'desktop');

        @include breakpoint('tablet') {
            height: getSpacing('large-vertical', 'tablet');
        }

        @include breakpoint('mobile') {
            height: getSpacing('large-vertical', 'mobile');
        }
    }

    &.xlarge {
        height: getSpacing('xlarge-vertical', 'desktop');

        @include breakpoint('tablet') {
            height: getSpacing('xlarge-vertical', 'tablet');
        }

        @include breakpoint('mobile') {
            height: getSpacing('xlarge-vertical', 'mobile');
        }
    }

}


</style>
